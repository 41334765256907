
/* width */
::-webkit-scrollbar {
	width: 7px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
	background: transparent !important;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
	background: #888;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
	background: #555;
  }